<template>
  <div class="lead-directory">
    <h2 class="section-header">
      <img src="@/assets/images/folder-icon.svg" alt="lead directory" />
      Lead Directory
    </h2>

    <CardsOverflow>
      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/big-white-arrow.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ directRecruit }}</div>
            <div class="text">Number of direct recruits</div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-details">
          <div class="image-container" style="rotate: 180deg;">
            <img src="@/assets/images/big-white-arrow.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ inDirectRecruit }}</div>
            <div class="text">Number of indirect recruits</div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-details">
          <div class="image-container" style="rotate: 180deg;">
            <img src="@/assets/images/properties.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ propertyCount }}</div>
            <div class="text">Number of properties</div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/logs.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ points }}</div>
            <div class="text">Points</div>
          </div>
        </div>
      </div>
    </CardsOverflow>

    <div class="lead-log" v-bind:style="{ height: leadLogHeight + 'px' }">
      <!-- TODO: add the round solid color behind each row of users -->
      <div class="log-header-container">
        <div class="lead-log-header">
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 2.46154H9.315L7.5825 0.504619C7.44286 0.347822 7.25998 0.220363 7.04881 0.132661C6.83764 0.0449577 6.60418 -0.000499666 6.3675 4.14253e-06H1.5C1.10218 4.14253e-06 0.720644 0.129674 0.43934 0.360488C0.158035 0.591302 0 0.904353 0 1.23077V14.7692C0 15.0957 0.158035 15.4087 0.43934 15.6395C0.720644 15.8703 1.10218 16 1.5 16H19.5C19.8978 16 20.2794 15.8703 20.5607 15.6395C20.842 15.4087 21 15.0957 21 14.7692V3.69231C21 3.36589 20.842 3.05284 20.5607 2.82203C20.2794 2.59121 19.8978 2.46154 19.5 2.46154ZM19.5 14.7692H1.5V4.92308H6.9825C7.38032 4.92308 7.76186 4.79341 8.04316 4.5626C8.32446 4.33178 8.4825 4.01873 8.4825 3.69231H1.5V1.23077H6.3675L8.325 3.44C8.39482 3.5184 8.48626 3.58213 8.59184 3.62598C8.69743 3.66983 8.81416 3.69256 8.9325 3.69231H19.5V14.7692Z"
              fill="#6886F0"
            />
          </svg>
          <h3>Lead Log</h3>
          <!-- <div class="user-type">
            <div>
              <span class="black"></span>
              <p>Basic</p>
            </div>
            <div>
              <span class="yellow"></span>
              <p>Supervisor</p>
            </div>
            <div>
              <span class="green"></span>
              <p>Advocates</p>
            </div>
            <div>
              <span class="purple"></span>
              <p>Realtor</p>
            </div>
            <div>
              <svg
                width="15"
                height="15"
                viewBox="0 0 9.5 9.5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 0L6.12257 3.45492L9.75528 3.45492L6.81636 5.59017L7.93893 9.04508L5 6.90983L2.06107 9.04508L3.18364 5.59017L0.244718 3.45492L3.87743 3.45492L5 0Z"
                  fill="#730768"
                />
              </svg>
              <p>Star realtor</p>
            </div>
            <div>
              <span class="cyan"></span>
              <p>Developer</p>
            </div>
            <div>
              <svg
                width="15"
                height="15"
                viewBox="0 0 9.5 9.5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 0L6.12257 3.45492L9.75528 3.45492L6.81636 5.59017L7.93893 9.04508L5 6.90983L2.06107 9.04508L3.18364 5.59017L0.244718 3.45492L3.87743 3.45492L5 0Z"
                  fill="#93A7ED"
                />
              </svg>
              <p>Star developer</p>
            </div>
          </div> -->
        </div>
        <div class="dropdown" @click="toggleList()">
          <img width="14" src="@/assets/images/dropdown.svg" alt="" />
        </div>
      </div>
      <div class="table-container ">
        <table>
          <thead>
            <tr>
              <th>Fullname</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Referral link</th>
            </tr>
          </thead>
          <tbody v-if="leadLog.length > 0">
            <tr v-for="(lead, index) of leadLog" :key="index">
              <td>{{ lead.firstname + " " + lead.lastname }}</td>
              <td>{{ lead.email }}</td>
              <td>{{ lead.phone || "" }}</td>
              <td class="d-flex align-center" v-if="lead.smartsubscribed === 'YES'"><span style="padding: 6px; margin-right: 5px; background: green;"></span>Subscribed</td>
              <td class="d-flex align-center" v-if="lead.smartsubscribed === 'NO'"><span style="padding: 6px; margin-right: 5px; background: red;"></span></td>
              <td>{{ lead.referalLink }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="empty"
          v-if="leadLog.length === 0 && fetchData2 === 'loaded'"
        >
          No Records
        </div>
        <div class="loading-container" v-if="fetchData2 === 'loading'">
          <Loader />
        </div>
      </div>
    </div>

    <div
      class="properties-log"
      v-bind:style="{ height: propertiesLogHeight + 'px' }"
    >
      <div class="log-header-container">
        <div class="lead-log-header">
          <svg
            width="33"
            height="25"
            viewBox="0 0 40 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.1 9.16615L0.462 8.48782L0 8.73782V9.16615H1.1ZM16.5 0.83282L17.138 0.154486C16.9517 0.0539995 16.7287 0 16.5 0C16.2713 0 16.0483 0.0539995 15.862 0.154486L16.5 0.83282ZM31.9 9.16615H33V8.73782L32.538 8.48782L31.9 9.16615ZM5.5 14.1662V13.3328H4.4V14.1662H5.5ZM14.3 14.1662H15.4V13.3328H14.3V14.1662ZM2.2 24.9995V9.16615H0V24.9995H2.2ZM1.738 9.84449L17.138 1.51115L15.862 0.154486L0.462 8.48782L1.738 9.84449ZM15.862 1.51115L31.262 9.84449L32.538 8.48782L17.138 0.154486L15.862 1.51115ZM30.8 9.16615V24.9995H33V9.16615H30.8ZM6.6 24.9995V14.1662H4.4V24.9995H6.6ZM5.5 14.9995H14.3V13.3328H5.5V14.9995ZM13.2 14.1662V24.9995H15.4V14.1662H13.2ZM11 19.9995H14.3V18.3328H11V19.9995ZM24.2 13.3328V19.9995H26.4V13.3328H24.2ZM28.6 3.33282V7.49949H30.8V3.33282H28.6Z"
              fill="#6886F0"
            />
          </svg>
          <h3>Properties log</h3>
          <div class="user-type ">
            <div class="">
              <span class="green indicator"></span>
              <span>Occupied</span>
            </div>
            <div>
              <span class="grey indicator"></span>
              <span>Vacant</span>
            </div>
          </div>
        </div>
        <div class="dropdown" @click="togglePropertiesList()">
          <img width="14" src="@/assets/images/dropdown.svg" alt="" />
        </div>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Landlord name</th>
              <th>ID</th>
              <th>No. of Units</th>
              <!-- <th>Phone</th> -->
              <th>Status</th>
            </tr>
          </thead>
          <tbody v-if="propertyLog.length > 0">
            <tr
              @click="togglePopup(landlord.id)"
              v-for="(landlord, index) of propertyLog"
              :key="index"
            >
              <td>{{ landlord.firstname + " " + landlord.lastname }}</td>
              <td>{{ landlord.usercode }}</td>
              <td>{{ landlord.propertiesCount }}</td>
              <!-- <td>{{ landlord.phone || "" }}</td> -->
              <!-- TODO: identify what this status is meant for. -->
              <td><span class="green"></span></td>
            </tr>

            <!-- <tr>
                            <td>Jane Sobowale</td>
                            <td>56w</td>
                            <td>3</td>
                            <td>082 345 7763</td>
                            <td><span class="grey"></span></td>
                        </tr> -->
          </tbody>
        </table>
        <div
          class="empty"
          v-if="propertyLog.length === 0 && fetchData3 === 'loaded'"
        >
          No Records
        </div>
        <div class="loading-container" v-if="fetchData3 === 'loading'">
          <Loader />
        </div>
      </div>
    </div>

    <div class="popup-background hide">
      <div class="popup-group">
        <div class="close-popup" @click="togglePopup()">&times;</div>
        <div class="popup">
          <table>
            <thead>
              <tr>
                <th>PIN</th>
                <th>IMAGE</th>
                <th>LOCATION</th>
                <th>DESCRIPTION</th>
                <th>RATE (&#8358;)</th>
              </tr>
            </thead>
            <tbody v-if="landlordProperties.length > 0">
              <tr v-for="(property, index) of landlordProperties" :key="index">
                <td>{{ property.pin }}</td>
                <td>
                  <img :src="property.images[0]" alt="" />
                </td>
                <td>{{ property.city + ", " + property.state }}</td>
                <td>{{ property.description }}</td>
                <td>
                  {{
                    property.denomination +
                      " " +
                      universalFormat(property.price)
                  }}
                  <span>{{ property.modeofpayment }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="empty"
            v-if="landlordProperties.length === 0 && fetchData === 'loaded'"
          >
            No Records
          </div>
          <div class="loading-container" v-if="fetchData === 'loading'">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardsOverflow from "./Layout/CardsOverflow";
import directory from "../services/directory";
import Loader from "./Loader";

export default {
  name: "LeadDirectory",
  components: {
    CardsOverflow,
    Loader
  },
  data() {
    return {
      directRecruit: 0,
      inDirectRecruit: 0,
      propertyCount: 0,
      points: 0,
      leadLog: [],
      propertyLog: [],
      landlordProperties: [],
      fetchData: "",
      fetchData2: "",
      fetchData3: "",

      leadLogHeight: "fit-content",
      leadLogHeightStore: 0,
      leadLogHeaderHeight: 0,

      propertiesLogHeight: "fit-content",
      propertiesLogHeightStore: 0,
      propertiesLogHeaderHeight: 0
    };
  },
  methods: {
    toggleList() {
      const tableContainer = document.querySelector(
        ".lead-log .table-container"
      );

      tableContainer.classList.toggle("hide");

      if (this.leadLogHeight === this.leadLogHeaderHeight) {
        this.leadLogHeight = this.leadLogHeightStore;
      } else {
        this.leadLogHeight = this.leadLogHeaderHeight;
      }
    },
    togglePropertiesList() {
      const tableContainer = document.querySelector(
        ".properties-log .table-container"
      );

      tableContainer.classList.toggle("hide");

      if (this.propertiesLogHeight === this.propertiesLogHeaderHeight) {
        this.propertiesLogHeight = this.propertiesLogHeightStore;
      } else {
        this.propertiesLogHeight = this.propertiesLogHeaderHeight;
      }
    },
    togglePopup(id = 0) {
      if (this.fetchData === "") {
        this.fetchData = "loading";
      }
      if (id !== 0) {
        directory
          .landlordProperties(id)
          .then(data => {
            if (data.success) {
              this.landlordProperties = data.properties;
            } else {
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.$toast.error("please check your network and refresh the page");
          })
          .finally(() => {
            this.fetchData = "loaded";
          });

        window.scrollTo(0, 0);
      }

      const popupBg = document.querySelector(".popup-background");

      popupBg.classList.toggle("hide");
    },
    universalFormat(value) {
      return value.toLocaleString();
    }
  },
  created() {
    const userID = this.$store.getters.getUser.id;
    directory
      .directRecruits(userID)
      .then(data => {
        if (data.success) {
          // TODO: this api return direct, indirect and property, it doesnt return points yet
          this.directRecruit = data.direct;
          this.inDirectRecruit = data.indirect;
          this.propertyCount = data.property;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      });

    this.fetchData2 = "loading";
    directory
      .leadLog(userID)
      .then(data => {
        if (data.success) {
          this.leadLog = data.leads;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData2 = "loaded";
      });

    this.fetchData3 = "loading";
    directory
      .propertyLog(userID)
      .then(data => {
        if (data.success) {
          this.propertyLog = data.leadswithproperty;
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch(() => {
        this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchData3 = "loaded";
      });
  },
  mounted() {
    // get lead log height;
    const leadLog = document.querySelector(".lead-log");

    const leadLogHeader = document.querySelector(
      ".lead-log .log-header-container"
    );
    const height = leadLog.offsetHeight + 50;

    this.leadLogHeight = height;
    this.leadLogHeightStore = height;
    this.leadLogHeaderHeight = leadLogHeader.offsetHeight;

    // get properties log height
    const propertiesLog = document.querySelector(".properties-log");
    const propertiesLogHeader = document.querySelector(
      ".properties-log .log-header-container"
    );
    const pHeight = propertiesLog.offsetHeight + 50;

    this.propertiesLogHeight = pHeight;
    this.propertiesLogHeightStore = pHeight;
    this.propertiesLogHeaderHeight = propertiesLogHeader.offsetHeight;
    this.toggleList();
    this.togglePropertiesList();
  }
};
</script>

<style scoped>
@import "../styles/section-header.css";
@import "../styles/table.css";
.lead-directory {
  padding-bottom: 50px;
}
/* card styling */
.card {
  margin-top: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 300px;
  height: 162px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}
.card:last-of-type {
  margin-right: 20px;
}
.card-details {
  padding: 45px 35px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
}
.card:first-of-type .card-details .image-container img {
  position: relative;
  left: -10px;
}
.card .image-container {
  background: var(--primary-color);
  width: 59px;
  height: 53px;
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price {
  font-family: "Gotham-Medium";
  text-align: center;
  font-size: 20px;
}
.text {
  text-align: center;
  min-width: 100px;
  max-width: 170px;
  font-size: 12px;
  font-family: "Gotham-Book";
  margin-top: 5px;
}
/* end of card styling */

/* lead log styling */
.lead-log,
.properties-log {
  background: #fff;
  margin-top: 33px;
  transition: all 0.5s;
  overflow-y: auto;
}
.lead-log::-webkit-scrollbar,
.properties-log::-webkit-scrollbar {
  width: 8px;
  background: #bbb;
}
.lead-log::-webkit-scrollbar-thumb,
.properties-log::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 4px;
}
.log-header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 55px;
  min-height: 60px;
}
.lead-log-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.lead-log-header > h3 {
  font-weight: 600;
  margin: 0 auto 0 30px;
}
.user-type {
  justify-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 20px 10px 0;
}
.user-type > div {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
}
.indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 3px;
}
.user-type p {
  margin-left: 5px;
  font-weight: 600;
  line-height: 2;
}
span.black {
  background: #000;
}
span.yellow {
  background: #ffd704;
}
span.green {
  background: #2fc643;
}
span.purple {
  background: #730768;
}
span.cyan {
  background: #93a7ed;
}
span.grey {
  background: #c4c4c4;
}
.dropdown {
  cursor: pointer;
  border-radius: 50%;
  margin: auto;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown:hover {
  background: #ccc;
}

/* table styling */
.table-container {
  box-shadow: unset;
  margin-top: 0;
}
.lead-log thead tr th,
.properties-log thead tr th {
  font-size: 16px;
}
.properties-log tbody tr {
  cursor: pointer;
}
.properties-log tbody tr:hover {
  background: #f5f5f5;
}
.properties-log tr td:last-of-type,
.properties-log tr th:last-of-type {
  text-align: center;
  min-width: 80px;
}
.properties-log tr td:nth-of-type(2),
.properties-log tr th:nth-of-type(2) {
  min-width: 70px;
}
.properties-log tr td:nth-of-type(3),
.properties-log tr th:nth-of-type(3) {
  text-align: center;
}

/* popup styling */
.popup-background {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 6;
}
.popup-group {
  position: relative;
  width: 700px;
  left: calc((100% - 700px) / 2);
  top: 10%;
  height: fit-content;
}
.close-popup {
  background: #fff;
  border-radius: 50%;
  color: #000;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-family: "Gotham-Book";
  font-size: 25px;
  font-weight: 600;
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.popup {
  background: #fff;
  height: fit-content;
  position: absolute;
  top: 16px;
  right: 16px;
  width: calc(100% - 16px);
  overflow: auto;
  max-height: 80vh;
  padding-bottom: 20px;
}
.popup::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #bbb;
}
.popup::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}
.popup table {
  border-collapse: collapse;
  width: 100%;
}
.popup thead tr {
  background: #fafbff;
}
.popup thead tr th {
  font-size: 14px;
}
.popup tbody tr td {
  padding: 10px 20px;
}
.popup tbody tr td:last-of-type {
  font-family: "Gotham-Book";
}
.popup tbody tr td:last-of-type span {
  font-size: 10px;
  display: inline-block;
  width: 100%;
}

/* general styling */
.hide {
  display: none;
}
@media (max-width: 1199.98px) {
  .card {
    margin-right: 30px;
    width: 300px;
  }
}
@media (max-width: 991.98px) {
  /* card styling */
  .card {
    width: 280px;
  }
  .card-details {
    padding: 45px 25px 0;
  }
  /* lead-log styling */
  .log-header-container {
    padding: 10px 30px;
  }
}
@media (max-width: 767.98px) {
  .popup-group {
    width: 95%;
    left: 2.5%;
  }
  .popup tbody tr td {
    padding: 5px;
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .card {
    margin-top: 0;
    width: 337px;
    max-width: 100%;
    height: 162px;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .card:last-of-type {
    margin-right: 0;
  }

  /* table styling */
  thead tr th {
    font-size: 14px;
  }
}
</style>
